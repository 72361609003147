import React, { useState, useRef } from "react"
import { Link, graphql } from "gatsby"
import { navigate } from 'gatsby-link'
import toast from "react-hot-toast"
import SimpleReactValidator from "simple-react-validator"

import Meta from "../../components/addon/meta"
import Schema from "../../components/addon/schema"
import Layout from "../../components/layout/layout"
import APIButton from "../../components/addon/button/apiButton"
import LinkButton from "../../components/addon/button/linkButton"

import useScript from "../../service/useScript"
import { acceptOnlyNumbers } from "../../service/helper"
import USER_API from "../../api/user"

import { handleHHLogin } from '../../service/auth'
import UseCustomValidator from "../../service/simpleValidator"

const OPDDepositInfo = (props) => {
    const [detailsType, setDetailsType] = useState('hh_no')
    const [hhNumber, setHHNumber] = useState('')
    const [mobile, setMobile] = useState('')
    const [otp, setOTP] = useState('')
    const [verifyOpen, setVerifyOpen] = useState(null);
    const [otpLoading, setOTPLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [patients, setPatients] = useState([])
    const [selectedPatient, setSelectedPatient] = useState(null)
    const [btnLoading, setBtnLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [mobileStep, setMobileStep] = useState('step1')
    const [data, setData] = useState(null)
    const { customMobileValidator } = UseCustomValidator()
    const [, forceUpdateForRegister] = useState();
    useScript("/assets/js/login-register.js");
    const [hhValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" > {message} </p>}
    )))
    const mobileValidator = customMobileValidator
    const [otpValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" >The OTP field is required.</p>}
    )))

    const metaTags = props?.data.allNodeTemplatePages.edges[0]?.node?.metatags ? props?.data.allNodeTemplatePages.edges[0].node.metatags : []
    const schema = props?.data.allNodeTemplatePages.edges[0]?.node?.relationships?.field_seo_schema ? props?.data.allNodeTemplatePages.edges[0]?.node?.relationships?.field_seo_schema : []
    const canonicalUrl = props?.data.allNodeTemplatePages.edges[0]?.metatags_on_page || {}

    const handleRadioBtn = (e) => {
        const { id } = e?.target
        setDetailsType(id)
    }

    const resetData = () => {
        setSuccess(false)
        setHHNumber('')
        setDetailsType('hh_no')
    }

    const validateUserHHNumber = () => {
        setLoading(true)
        USER_API.validateHHNumber(hhNumber)
        .then(res => {
            if(res) {
                getDetailsHHNumber()
            } else {
                toast.error("Invalid HH Number")
                setLoading(false)
            }
        })
        .catch(err => {
            console.log(err)
            toast.error("Invalid HH Number")
            setLoading(false)
        })
    }

    const getDetailsHHNumber = async () => {
        try {
            const result = await handleHHLogin(hhNumber)
            setData(result)
            setSuccess(true)
            setLoading(false)
        } catch (error) {
            toast.error("Something went wrong")
            console.log(error)
            setLoading(false)
            setSuccess(false)
        }
    }

    const handleHHSubmit = (e) => {
        e.preventDefault();
        const hhResult = hhValidator.current.allValid()
        if(!hhResult) {
            hhValidator.current.showMessages()
            forceUpdateForRegister(1)
        }
        if(hhResult && hhNumber?.length) {
            validateUserHHNumber()
        }
    }

    const gotoConsultationDetails = () => {
        let obj = { pinNo: data?.pinNo, mobileno: data?.mobileno, fullname: data?.fullname, email: data?.email }
        navigate(`/opd-deposit/details`, { state: { user: obj }})
    }

    const onVerify = () => {
        const mobileResult = mobileValidator.current.allValid()
        if (mobileResult === false) {
            mobileValidator.current.showMessages()
            forceUpdateForRegister(3)
            setVerifyOpen(false);
        }else{
            onResendOTP()
        }
    }

    const onResendOTP = () => {
        setOTPLoading(true)
        USER_API.sendOTP({ mobileNo : mobile, "otpFor": "online_reports" })
        .then(response => {
            setVerifyOpen(true);
            toast.success("OTP sucessfully sent on mobile no.")
            setOTPLoading(false)
        })
        .catch(err => {
            console.log(err)
            toast.error("Something went wrong. Please try again later")
            setVerifyOpen(false);
            setOTPLoading(false)
        })
    }

    const validateOTP = () => {
        return USER_API.verifyOTP(otp)
        .then(res => {
            return res
        })
        .catch(err => {
            console.log(err)
            return false
        })
    }

    const validateMobileNo = async () => {
        const mobileResult = mobileValidator.current.allValid()
        const otpResult = otpValidator.current.allValid()
        if (mobileResult === false) {
            mobileValidator.current.showMessages()
            forceUpdateForRegister(4)
        }
        if (!verifyOpen && mobileResult && otp.length === 0) {
            toast.error("Please verify OTP before proceeding")
        }
        if (verifyOpen && mobileResult && otpResult === false) {
            otpValidator.current.showMessages()
            forceUpdateForRegister(5)
        }
        if(mobileResult && otpResult && otp.length !== 0) {
            setBtnLoading(true)
            const otpVerify = await validateOTP()
            if(otpVerify) {
                toast.success("Mobile number verified successfully")
                USER_API.getUserFromMobileNumber(mobile).then(response => {
                    if(response?.Data){
                        setPatients(response.Data)
                        if(response?.Data?.length === 1) {
                            setSelectedPatient(response.Data[0])
                            setMobileStep('step3')
                            setSuccess(true)
                        } else {
                            setLoading(false)
                            setBtnLoading(false)
                            setMobileStep('step2')
                            window.scrollTo(0, 0);
                        }
                        
                    }
                }).catch(err => {
                    console.log(err);
                    toast.error("Something went wrong")
                    setLoading(false)
                    setBtnLoading(false)
                })
            } else {
                setBtnLoading(false)
                toast.error("Invalid OTP")
            }
        }
    }

    const handleMobileNoSubmit = async () => {
        if(detailsType === 'mobile_no') {
            if(mobileStep === 'step1') {
                validateMobileNo()
            } else if(mobileStep === 'step2') {
                sentSelectedPatient()
            }
        }
    }

    const sentSelectedPatient = () => {
        if(selectedPatient?.pinNo) {
            setData(selectedPatient)
            setSuccess(true)
            setLoading(false)
            setMobileStep('step3')
            window.scrollTo(0, 0);
        } else {
            toast.error("Select Patient before proceeding")
        }
    }

    const handleMobileBack = () => {
        if(detailsType === 'mobile_no') {
            if(mobileStep === 'step1') {
                navigate('/online-services')
            } else if(mobileStep === 'step2') {
                setMobileStep('step1')
                setVerifyOpen(false)
                setMobile('')
                setOTP('')
                setPatients(null)
                otpValidator.current.hideMessages()
                mobileValidator.current.hideMessages()
                forceUpdateForRegister(6)
                setSelectedPatient(null)
            } else if(mobileStep === 'step3') {
                setMobileStep('step2')
                setSuccess(false)
            }
        }
    }

    return (
        <Layout>
            <Meta
                files={{
                    js: [],
                    css: ['/assets/css/login.css', '/assets/css/confirmpay.css', '/assets/css/accessibility.css', '/assets/css/services.css', '/assets/css/common.css']
                }}
                tags={{metaTags,canonicalUrl}}
            />
            <Schema schema={schema} />
            <main className="innerpage">
                {success 
                ? <section className="confirmation_section section-bg section-py">
                    <div className="container">
                        <div className="white_rounded_box">
                            <div className="tankyou_div">
                                <h2 className="section-heading">Confirmation</h2>
                                <div className="check_mark">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="49.078" height="49.354" viewBox="0 0 49.078 49.354">
                                        <g data-name="Group 2657" transform="translate(0)">
                                            <path data-name="Path 3040" d="M52.791,24.176l-1.036-.709A3.209,3.209,0,0,1,50.5,19.924l.327-1.2a3.26,3.26,0,0,0-2.617-4.089l-1.254-.218A3.182,3.182,0,0,1,44.287,11.8l-.218-1.254A3.228,3.228,0,0,0,39.98,7.986l-1.2.382a3.267,3.267,0,0,1-3.543-1.2l-.763-1.09a3.215,3.215,0,0,0-4.8-.491l-.927.872a3.207,3.207,0,0,1-3.761.436L23.9,6.3a3.278,3.278,0,0,0-4.579,1.635l-.491,1.2a3.13,3.13,0,0,1-3.162,2.017l-1.254-.055a3.191,3.191,0,0,0-3.38,3.434l.109,1.254A3.275,3.275,0,0,1,9.18,19l-1.145.491a3.257,3.257,0,0,0-1.581,4.579l.654,1.09a3.207,3.207,0,0,1-.382,3.761l-.818.927a3.181,3.181,0,0,0,.6,4.8l1.036.709A3.209,3.209,0,0,1,8.8,38.9l-.491,1.254a3.26,3.26,0,0,0,2.617,4.089l1.254.218a3.182,3.182,0,0,1,2.671,2.617l.218,1.254a3.228,3.228,0,0,0,4.089,2.562l1.2-.382a3.267,3.267,0,0,1,3.543,1.2l.763,1.036a3.215,3.215,0,0,0,4.8.491l.927-.872a3.207,3.207,0,0,1,3.761-.436l1.09.6a3.278,3.278,0,0,0,4.579-1.635l.491-1.2a3.129,3.129,0,0,1,3.162-2.017l1.254.055a3.191,3.191,0,0,0,3.38-3.434l-.055-1.254a3.275,3.275,0,0,1,1.963-3.216l1.145-.491a3.257,3.257,0,0,0,1.581-4.579l-.654-1.09a3.207,3.207,0,0,1,.382-3.761l.818-.927A3.172,3.172,0,0,0,52.791,24.176Z" transform="translate(-5.081 -4.733)" fill="#33a470"></path>
                                            <g data-name="Group 2463" transform="translate(14.719 18.535)">
                                                <line data-name="Line 241" x2="6.375" y2="6.375" transform="translate(0 6.375)" fill="none" stroke="#fff" stroke-linecap="round" strokeWidth="4"></line>
                                                <line data-name="Line 242" y1="12.75" x2="13.388" transform="translate(6.375)" fill="none" stroke="#fff" stroke-linecap="round" strokeWidth="4"></line>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="thankyou_content">
                                    <p>Dear <span>{data?.fullname || data?.pat_name},</span> {data.regType || 'HH'} No. {data?.pinNo || data?.pat_pin_no} </p>
                                    <p>Kindly confirm your name and HH No./EX No.</p>
                                    {
                                        data.regType === 'HH' ?
                                        <>
                                    <p className="mt-4"> <span>If correct</span> - Please proceed</p>
                                    <p> <span>If incorrect</span> – Please go back and enter your correct details.</p>
                                        </>
                                        :
                                        <p className="mt-4">Only registered patients can make on- line deposit. To register <Link to="/user/register#create_hh">click here</Link>.</p>
                                    }
                                    
                                </div>
                                <div className="btn_wrap btn_group">
                                    <button className="btn btn-primary btn-primary-solid back_btn" onClick={() => detailsType === 'mobile_no' ? handleMobileBack() : resetData()}>Back</button>
                                    {data.regType === 'HH' && <a className="btn btn-primary proceed_btn" onClick={gotoConsultationDetails}>Proceed</a>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                : <section className="enter_details_section section-bg section-py">
                    <div className="container">
                        <h2 className="section-heading">Enter your details</h2>
                        <div className="white_rounded_box">
                            <div className="form_wrap">
                                <p className="note">
                                    Note: This facility is available for registered patients only.
                                </p>
                                {mobileStep === 'step1' 
                                ? <>
                                    <div className="row">
                                        <div className="col-md-10">
                                            <div className="form-group radio-group">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="custom-control custom-radio">
                                                            <input checked={detailsType === 'hh_no'} onChange={handleRadioBtn} value="hh_no" type="radio" id="hh_no" />
                                                            <label for="hh_no">HH No</label>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-3">
                                                        <div className="custom-control custom-radio">
                                                            <input checked={detailsType === 'mobile_no'} onChange={handleRadioBtn} value="mobile_no" type="radio" id="mobile_no" />
                                                            <label for="mobile_no">Mobile No.</label>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {detailsType === 'hh_no' && <div className="col-md-6">
                                            <div className="form-group -animated">   
                                                <label>Enter HH Number <span className="mandatory">*</span></label>
                                                <input className="form-control" type="text" value={hhNumber} onChange={(e) => setHHNumber(acceptOnlyNumbers(e?.target?.value))} name="hhNumber" />
                                                {hhValidator.current.message('HhNumber', hhNumber, 'required|min:3|max:10')}                                           
                                            </div>
                                        </div>}
                                        {detailsType === 'mobile_no' && <div className="col-lg-12 col-md-12">
                                            <div className="row otp_row">
                                                <div className="col-md-4">
                                                    <div className={`form-group -animated ${mobile ? '-active' : ''}`}>
                                                        <label>Mobile <span className="mandatory">*</span></label>
                                                        <input onChange={(e) => setMobile(acceptOnlyNumbers(e.target.value))} name="mobile" value={mobile} className="form-control" type="text" />
                                                        {mobileValidator.current.message('Mobile ', mobile , 'required|min:10|max:10|validateMobile')}
                                                    </div>
                                                    {!verifyOpen && <LinkButton title={`Verify with OTP`} onBtnClick={onVerify} loading={otpLoading} />}
                                                </div> 
                                                {verifyOpen &&
                                                    <div className="col-md-4">
                                                        <div className={`form-group -animated ${otp ? '-active' : ''}`}>
                                                            <label>Enter OTP <span className="mandatory">*</span></label>
                                                            <input className="form-control" type="text" name="OTP" value={otp} onChange={(e) => setOTP(acceptOnlyNumbers(e.target.value))} />
                                                            {otpValidator.current.message('OTP', otp, 'required')}
                                                        </div>
                                                        <LinkButton title={`Resend OTP`} resend onBtnClick={onResendOTP} loading={otpLoading} />
                                                    </div>
                                                }
                                            </div>
                                        </div>}
                                    </div>
                                </>
                                : null}
                                {detailsType === 'mobile_no' && mobileStep === 'step2' 
                                ? <div className="confirm_pay mt-4">
                                    <div className="common_section appoinment_for px-0 pb-0">
                                        <h3 className="mb-3">Select Patient</h3>
                                        <div className="user-carousel row d-flex-wrap">
                                            {patients &&
                                                patients.map((p, index) => {
                                                    return (
                                                        <div className="card-slide mx-2 mb-3 width-100px" key={index}>
                                                            <input checked={selectedPatient?.pin === p?.pinNo || selectedPatient?.pinNo === p?.pinNo } onChange={ () => setSelectedPatient(p) } type="checkbox" id={'a' + index} name="patient"/>
                                                            <div className="checkbox_card">
                                                                <label className="px-2" for={'a' + index}>
                                                                    {p.gender === 'M' ? 
                                                                        <div>
                                                                            <img src="/assets/images/icons/appointment_user_male.svg" alt="" className="normal" />
                                                                            <img src="/assets/images/icons/appointment_user_male_active.svg" alt="" className="active" />
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <img src="/assets/images/icons/appointment_user_female.svg" alt="" className="normal" />
                                                                            <img src="/assets/images/icons/appointment_user_female_active.svg" alt="" className="active" />
                                                                        </div>
                                                                    }
                                                                    {`${p.firstname} ${p.lastname}`} <br />
                                                                    <span>{p.pinNo}</span>
                                                                </label>
                                                            </div> 
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                : null}
                                <div className="btn_group_left">
                                    {detailsType === 'mobile_no' 
                                    ? <>
                                        <APIButton title={`Back`} 
                                            onBtnClick={(e) => handleMobileBack(e)}  
                                            className={`btn btn-primary btn-primary-solid back_btn`} 
                                        />
                                        <APIButton title={`Continue`} 
                                            loading={btnLoading} 
                                            disabled={btnLoading} 
                                            onBtnClick={(e) => handleMobileNoSubmit(e)}  
                                            className={`btn btn-primary continue_btn`} 
                                        />
                                    </>
                                    : <>
                                        <Link to="/online-services" className="btn btn-primary btn-primary-solid back_btn">Back</Link>
                                        <APIButton title={`Continue`} 
                                            loading={loading} 
                                            disabled={loading} 
                                            onBtnClick={(e) => handleHHSubmit(e)}  
                                            className={`btn btn-primary continue_btn`} 
                                        />
                                    </>} 
                                    {/* <button className="btn btn-primary continue_btn">Continue</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
            </main>
        </Layout>
    )
}

export const query = graphql`
query opdDepositQuery {
    allNodeTemplatePages(filter: {path: {alias: {regex: "/opd-deposit$/"}}}) {
		edges {
		  node {
			id
			title
			path {
			  alias
			}
            relationships{
                field_seo_schema{
                    field_seo_title
                    field_seo_schema_text_area
                }
            }
			metatags: metatag_normalized {
			  attributes {
				content
				name
				property
			  }
			}
            metatags_on_page: field_meta_tags{
                canonical_url
              }
		  }
		}
	}
  }  
`
export default OPDDepositInfo